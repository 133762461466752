jQuery(document).ready(function ($) {
    const targetNode = document.body; // or specify the target node where mutations are expected
    const config = {childList: true, subtree: true};

    let report;

    const observer = new MutationObserver((mutationsList, observer) => {
        for (let mutation of mutationsList) {
            if (mutation.type === 'childList') {
                for (let node of mutation.addedNodes) {
                    if (node.id === 'report') {
                        // Get report from DOM.
                        report = $(node);

                        report.on('change', 'tbody input[type="checkbox"]', function(e) {
                            e.preventDefault()

                            const checkbox = $(this)
                            const product = $(this).closest('tr')

                            if (product.data('part-number') === 111788 || product.data('part-number') === 111790) {
                                if (checkbox.is(':checked')) {
                                    window.show_product(111167)
                                } else {
                                    window.hide_product(111167)
                                }
                            }

                            window.calculate_prices()
                        });

                        handle_checkboxes()
                    }
                }
            }
        }
    });
    observer.observe(targetNode, config);

    function handle_checkboxes() {
        $('#report').on('change', 'input[type="checkbox"]', function (e) {
            e.preventDefault();

            const checkbox = $(this);
            const product = checkbox.closest('tr');
            const regulation = $('#report').attr('data-regulation');

            if (regulation === 'cascade') {
                if (product.data('part-number') === 'S53990' && checkbox.is(':checked')) {
                    window.show_product('S53990');
                    window.hide_product('S62518');
                    window.hide_product('S101679');
                    window.hide_product(7764832);
                }

                if (product.data('part-number') === 'S62518' && checkbox.is(':checked')) {
                    window.show_product('S62518');
                    window.show_product('S101679');
                    window.hide_product('S53990');
                    window.hide_product(7764832);
                }

                if (product.data('part-number') === 'S101679' && checkbox.is(':checked')) {
                    window.show_product('S62518');
                    window.show_product('S101679');
                    window.hide_product('S53990');
                    window.hide_product(7764832);
                }

                if ( product.data( 'part-number' ) === 7764832 && checkbox.is( ':checked' ) ) {
                    window.show_product( 7764832 );
                    window.hide_product( 'S53990' );
                    window.hide_product( 'S62518' );
                    window.hide_product( 'S101679' );
                }
            }

            if (regulation === 'gbs-cascade') {
                if (product.data('part-number') === 'S53990' && checkbox.is(':checked')) {
                    window.show_product('S53990');
                    window.hide_product('S62518');
                    window.hide_product('S100865');
                    window.hide_product(7764832);
                }

                if (product.data('part-number') === 'S62518' && checkbox.is(':checked')) {
                    window.show_product('S62518');
                    window.show_product('S100865');
                    window.hide_product('S53990');
                    window.hide_product(7764832);
                }

                if (product.data('part-number') === 'S100865' && checkbox.is(':checked')) {
                    window.show_product('S62518');
                    window.show_product('S100865');
                    window.hide_product('S53990');
                    window.hide_product(7764832);
                }

                if ( product.data( 'part-number' ) === 7764832 && checkbox.is( ':checked' ) ) {
                    window.show_product( 7764832 );
                    window.hide_product( 'S53990' );
                    window.hide_product( 'S62518' );
                    window.hide_product( 'S100865' );
                }
            }


            if (product.data('part-number') === 7764832 && checkbox.is(':checked') && ( !window.product_exists( 7780037 ) || !window.product_exists( 7621759 ) )) {
                window.show_product( 7721859 ) // Previous part number was 7693588.
            } else {
                window.hide_product( 7721859 ) // Previous part number was 7693588.
            }

            window.calculate_prices()
        })
    }
})
